// //////////////////////////////////////////////////////////////////////////////////////////////////
// ==================================================================================================
//
// Variables:
//
// * Factors
// * Website width
// * Colors
// * Font
// * Z indexs
// * Breakpoints
// * Transitions
// * Animation timings
// * Components
//   - Scrollbar
//
// ==================================================================================================
// //////////////////////////////////////////////////////////////////////////////////////////////////

// Factors (using in variables)
// ============================================================
const SPACING_FACTOR = 5
const GRID_SYSTEM = 12

export default {
  // Factors
  // ============================================================
  FACTORS: {
    SPACING: SPACING_FACTOR,
    GRID: GRID_SYSTEM
  },

  // Website width
  // ============================================================
  WEBSITE_WIDTH: 414,
  WEBSITE_WIDTH_PX: '414px',

  // Colors
  // ============================================================
  COLORS: {
    // Base
    // ------------------------------
    // Transparent
    TRANSPARENT: 'transparent',
    // Black
    BLACK: '#000000',
    // White
    WHITE: '#FFFFFF',
    // Gray
    GRAY_1: '#D0D0D0',
    GRAY_2: '#AFB4BC',
    GRAY_3: '#8E95A1',
    GRAY_4: '#C6C6C6',
    GRAY_5: '#E0E0E0',
    GRAY_6: '#1D2126',
    GRAY_7: '#E5E5E5',
    GRAY_8: '#8C8A8A',
    GRAY_9: '#F5F5F5',
    GRAY_10: '#E3E3E3',
    GRAY_11: '#EEEEED',
    GRAY_12: '#707070',
    GRAY_13: '#F8F8F8',
    GRAY_14: '#DADDE0',
    GRAY_15: '#898989',
    GRAY_16: '#CBCBCB',
    // Red
    RED_1: '#DD4B39',
    RED_2: '#FA295C',
    RED_3: '#EC2323',
    RED_4: '#C64040',
    RED_5: '#FF2929',
    // Yellow
    YELLOW_1: '#EFC553',
    YELLOW_2: '#FFFBEC',
    // Green
    GREEN_1: '#C6C700',
    GREEN_2: '#449C2E',
    GREEN_3: '#56ECA1',
    GREEN_4: '#3EABBA',
    GREEN_5: '#3CC480',
    // Orange
    ORANGE_1: '#E04619',
    ORANGE_2: '#FFF6EF',
    // Brown
    BROWN_1: '#631400',
    BROWN_2: '#957055',
    // Purple
    PURPLE_1: '#7584D3',
    // Blue
    BLUE_1: '#94BED3',
    BLUE_2: '#3084BC',
    BLUE_3: '#104366',
    BLUE_4: '#4C7898',
    BLUE_5: '#19304C',
    BLUE_6: '#28506E',
    BLUE_7: '#5FABDE',
    BLUE_8: '#52CAF5',
    BLUE_9: '#477DCF',
    BLUE_10: '#C1D9EA',
    // Light Blue
    LIGHT_BLUE_1: '#F2F5F9',
    LIGHT_BLUE_2: '#F8F8FD',
    LIGHT_BLUE_3: '#A4CFEB',
    LIGHT_BLUE_4: '#D7E8F4',
    LIGHT_BLUE_5: '#EEF8FF',
    // Overlay
    OVERLAY_1: 'rgba(0, 58, 98, 0.88)',
    OVERLAY_2: 'rgba(255, 255, 255, 0.3)',
    OVERLAY_3: 'rgba(0, 0, 0, 0.5)',
    OVERLAY_4: 'rgba(255, 255, 255, 0.58)',
    OVERLAY_5: 'rgba(238, 238, 237, 0.88)',
    OVERLAY_6: 'rgba(148, 190, 211, 0.3)',
    OVERLAY_7: 'rgba(68, 156, 46, 0.75)',
    OVERLAY_8: 'rgba(198, 199, 0, 0.75)',
    OVERLAY_9: 'rgba(224, 70, 25, 0.75)',
    OVERLAY_10: 'rgba(99, 20, 0, 0.75)',
    OVERLAY_11: 'rgba(0, 0, 0, 0.33)',
    OVERLAY_12: 'rgba(4, 68, 111, 0.75)',
    // Shadow
    SHADOW_1: '0 3px 20px rgba(0, 0, 0, 0.16)',
    SHADOW_2: '0 2px 10px rgba(95, 171, 222, 0.29)',
    SHADOW_3: '0 3px 6px rgba(0, 0, 0, 0.16)',
    SHADOW_4: '0 5px 10px rgba(0, 0, 0, 0.16)',
    SHADOW_5: '0 0 15px 0 rgba(0, 0, 0, 0.2)',
    // Border
    BORDER_BLUE_1: '#E4EEF7',

    // Apply - bring 'Base' color create a new name for easy using
    // ------------------------------
    // Primary
    PRIMARY_1: '#000000',
    PRIMARY_2: '#196FAA',
    // Status
    DISABLED: '#EEEEEE',
    ERROR: '#FF6565',
    SUCCESS: '#51A380',
    // Scrollbar
    SCROLLBAR_DEFAULT: '#B5B5B5',
    SCROLLBAR_HOVER: '#858585',
    SCROLLBAR_ACTIVE: '#858585'
  },

  // Font
  // ============================================================
  FONT: {
    FAMILIES: {
      FIRST: {
        WEIGHTS: {
          REGULAR: 'Roboto-Regular',
          MEDIUM: 'Roboto-Medium',
          BOLD: 'Roboto-Bold',
        },
        SIZES: {
          FS_10: 10,
          FS_11: 11,
          FS_12: 12,
          FS_14: 14,
          FS_15: 15,
          FS_16: 16,
          FS_18: 18,
          FS_20: 20,
          FS_22: 22,
          FS_24: 24,
          FS_30: 30,
          FS_36: 36,
          FS_40: 40,
          FS_42: 42,
          FS_44: 44,
          FS_48: 48,
          FS_54: 54,
        },
        LINE_HEIGHT_FACTOR: 1.3
      }
    }
  },

  // Zindexs
  // ============================================================
  Z_INDEXS: {
    LV_1: 1,
    LV_2: 9,
    LV_3: 10,
    LV_4: 11,
    LV_5: 99,
    LV_6: 100,
    LV_7: 101,
    LV_8: 999,
    LV_9: 1000,
    LV_10: 1001
  },

  // Breakpoints
  // ============================================================
  BREAKPOINTS: {
    // Mobile
    BP_320: '320px',
    BP_321: '321px',
    BP_359: '359px',
    BP_360: '360px',
    BP_361: '361px',
    BP_374: '374px',
    BP_375: '375px',
    BP_376: '376px',
    BP_413: '413px',
    BP_414: '414px',
    BP_415: '415px',

    // Phablet
    BP_479: '479px',
    BP_480: '480px',
    BP_481: '481px',
    BP_639: '639px',
    BP_640: '640px',
    BP_641: '641px',
    BP_666: '666px',
    BP_667: '667px',
    BP_668: '668px',
    BP_735: '735px',
    BP_736: '736px',
    BP_737: '737px',

    // Tablet
    BP_767: '767px',
    BP_768: '768px',
    BP_769: '769px',
    BP_811: '811px',
    BP_812: '812px',
    BP_813: '813px',
    BP_1023: '1023px',
    BP_1024: '1024px',
    BP_1025: '1025px',

    // Laptop
    BP_1279: '1279px',
    BP_1280: '1280px',
    BP_1281: '1281px',
    BP_1365: '1365px',
    BP_1366: '1366px',
    BP_1367: '1367px',
    BP_1439: '1439px',
    BP_1440: '1440px',
    BP_1441: '1441px',
    BP_1599: '1599px',
    BP_1600: '1600px',
    BP_1601: '1601px',

    // Desktop
    BP_1919: '1919px',
    BP_1920: '1920px',
    BP_1921: '1921px',
    BP_2559: '2559px',
    BP_2560: '2560px'
  },

  // Transitions
  // ============================================================
  TRANSITIONS: {
    DEFAULT: 'all 0.3s ease'
  },

  // Animation timings
  // ============================================================
  ANIMATION_TIMINGS: {
    ELASTIC: 'cubic-bezier(.835, -.005, .06, 1)'
  },

  // Components
  // ============================================================

  // Button
  // ------------------------------
  BUTTON: {
    WIDTHS: {
      W_200: 200,
      W_275: 275,
    },
    HEIGHTS: {
      H_35: 35,
      H_40: 40
    },
    BORDER_RADIUSES: {
      BR_10: 10
    }
  },

  // Scrollbar
  // ------------------------------
  SCROLLBAR: {
    WIDTH: 8,
    HEIGHT: 8,
    BORDER_RADIUS: 8
  },

  // Field
  // ------------------------------
  FIELD: {
    // WIDTHS: {
    //   W_440: 440
    // },
    // HEIGHTS: {
    //   H_44: 44
    // },
    // BORDER: {
    //   WIDTHS: {
    //     W_2: 2
    //   },
    //   RADIUSES: {
    //     RD_6: 6
    //   }
    // },
    // CHECKBOX: {
    //   SIZE: 18,
    //   BORDER: {
    //     WIDTH: 2,
    //     RADIUS: 6
    //   }
    // }
  },

  // Modal
  // ------------------------------
  MODAL: {
    WIDTHS: {
      W_300: 300
    },

    CONTAINER: {
      C_1: {
        TRANSFORM_START: 'translateY(-30px)',
        TRANSFORM_END: 'translateY(0)',
        SPACING: 30,
        BUTTON_CLOSE_SPACING_TOP: 15,
        BUTTON_CLOSE_SPACING_RIGHT: 15
      }
    },

    HEADER: {
      H_1: {
        PADDING_TOP: 35,
        PADDING_BOTTOM: 25,
        PADDING_HORIZONTAL: 35,
        BORDER_TOP_RIGHT_RADIUS: 10,
        BORDER_TOP_LEFT_RADIUS: 10
      }
    },

    BODY: {
      B_1: {
        PADDING_BOTTOM: 50, // 30
        PADDING_HORIZONTAL: 35,
      },

      B_2: {
        PADDING_TOP: 35,
        PADDING_BOTTOM: 35,
        PADDING_HORIZONTAL: 35,
        BORDER_TOP_RIGHT_RADIUS: 10,
        BORDER_TOP_LEFT_RADIUS: 10
      }
    },

    FOOTER: {
      F_1: {
        PADDING_BOTTOM: 35,
        PADDING_HORIZONTAL: 35,
        BORDER_BOTTOM_RIGHT_RADIUS: 10,
        BORDER_BOTTOM_LEFT_RADIUS: 10
      },

      F_2: {
        PADDING_BOTTOM: 30,
        PADDING_HORIZONTAL: 35,
        BORDER_BOTTOM_RIGHT_RADIUS: 10,
        BORDER_BOTTOM_LEFT_RADIUS: 10
      }
    }
  },

  // CMS Layout
  // ------------------------------
  CMS_LAYOUT: {
    TOPBAR: {
      HEIGHT: 70
    },

    SIDEBAR: {
      WIDTH: 260
    },

    CONTENT: {
    }
  },

  // Notification
  // ------------------------------
  NOTIFICATION: {
    WIDTHS: {
      DEFAULT: 665 // Not use 'W_' becuase this variable is global
    },
    BUTTON_CLOSE: {
      WIDTH: 75
    }
  }
}
