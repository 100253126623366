import {
  injectGlobal
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'

injectGlobal`
  .text-base {
    /* Parent
    ------------------------------- */
    /* Childrens
    ------------------------------- */
    /* Modifiers
    ------------------------------- */
    &.is-underline {
      line-height: 1;
      border-bottom: 1px solid ${VARIABLES.COLORS.BLUE_2};
    }
    
    &.is-underline-white {
      line-height: 1;
      border-bottom: 1px solid ${VARIABLES.COLORS.WHITE};
    }

    &.is-splashscreen-title {
    }

    &.is-splashscreen-description {
    }

    &.is-password-note {
      display: block;
      text-align: right;
      font-size: 11px;
      top: -10px;
    }

    &.is-ellipsis-two-line {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &.is-side-menu-name {
      word-wrap: break-word;
    }

    &.is-inline {
      display: inline;
    }

    &.is-bold {
      font-weight: bold;
    }

    &.is-menu-lucky-draw {
      background: linear-gradient(180deg, rgba(248, 236, 213, 0.83) 0%, rgba(220, 175, 16, 0.83) 52.08%, rgba(248, 236, 213, 0.83) 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &.is-break-all {
      word-break: break-all;
    }

    &.is-no-wrap {
      white-space: nowrap;
    }

    &.is-stamp-sub {
      white-space: pre-line;
      width: 60%;
    }

    /* Media queries
    ------------------------------- */
    &.is-splashscreen-title {
      @media (max-height: 639px) {
        /* font-size: 30px; */
      }

      @media (min-height: 640px) and
          (max-height: 736px) {
      }
    }

    &.is-splashscreen-description {
      @media (max-height: 639px) {
      }

      @media (min-height: 640px) and
             (max-height: 736px) {
      }
    }
  }

  .is-mobile-safari {
    .text-base {
      &.is-phone-country-id {
        top: 2px;
      }
    }
  }
`
