import { css } from "styled-components";
import { default as VARIABLES } from "./../../../themes/styles/bases/variables"; // Use relative path for React Styleguidist

// //////////////////////////////////////////////////////////////////////////////////////////////////
// ==================================================================================================
//
// Typographys:
//
// First (Roboto)
// ------------------------------
// * Regular
//   - TYPOGRAPHYS.FIRST.REGULAR.FS_10
//   - TYPOGRAPHYS.FIRST.REGULAR.FS_11
//   - TYPOGRAPHYS.FIRST.REGULAR.FS_12
//   - TYPOGRAPHYS.FIRST.REGULAR.FS_14
//   - TYPOGRAPHYS.FIRST.REGULAR.FS_15
//   - TYPOGRAPHYS.FIRST.REGULAR.FS_16
//   - TYPOGRAPHYS.FIRST.REGULAR.FS_18
//   - TYPOGRAPHYS.FIRST.REGULAR.FS_20
//   - TYPOGRAPHYS.FIRST.REGULAR.FS_22
//   - TYPOGRAPHYS.FIRST.REGULAR.FS_30
//   - TYPOGRAPHYS.FIRST.REGULAR.FS_40
//   - TYPOGRAPHYS.FIRST.REGULAR.FS_48
//   - TYPOGRAPHYS.FIRST.REGULAR.FS_54
//
// * Medium
//   - TYPOGRAPHYS.FIRST.MEDIUM.FS_10
//   - TYPOGRAPHYS.FIRST.MEDIUM.FS_11
//   - TYPOGRAPHYS.FIRST.MEDIUM.FS_12
//   - TYPOGRAPHYS.FIRST.MEDIUM.FS_14
//   - TYPOGRAPHYS.FIRST.MEDIUM.FS_15
//   - TYPOGRAPHYS.FIRST.MEDIUM.FS_16
//   - TYPOGRAPHYS.FIRST.MEDIUM.FS_18
//   - TYPOGRAPHYS.FIRST.MEDIUM.FS_20
//   - TYPOGRAPHYS.FIRST.MEDIUM.FS_22
//   - TYPOGRAPHYS.FIRST.MEDIUM.FS_30
//   - TYPOGRAPHYS.FIRST.MEDIUM.FS_40
//   - TYPOGRAPHYS.FIRST.MEDIUM.FS_48
//   - TYPOGRAPHYS.FIRST.MEDIUM.FS_54
//
// * Bold
//   - TYPOGRAPHYS.FIRST.BOLD.FS_10
//   - TYPOGRAPHYS.FIRST.BOLD.FS_11
//   - TYPOGRAPHYS.FIRST.BOLD.FS_12
//   - TYPOGRAPHYS.FIRST.BOLD.FS_14
//   - TYPOGRAPHYS.FIRST.BOLD.FS_15
//   - TYPOGRAPHYS.FIRST.BOLD.FS_16
//   - TYPOGRAPHYS.FIRST.BOLD.FS_18
//   - TYPOGRAPHYS.FIRST.BOLD.FS_20
//   - TYPOGRAPHYS.FIRST.BOLD.FS_22
//   - TYPOGRAPHYS.FIRST.BOLD.FS_30
//   - TYPOGRAPHYS.FIRST.BOLD.FS_40
//   - TYPOGRAPHYS.FIRST.BOLD.FS_48
//   - TYPOGRAPHYS.FIRST.BOLD.FS_54
//
// Notice: Color eg. plain text, link etc. can set at variables.js or 'color' props of Text/TextBase component
//
// ==================================================================================================
// //////////////////////////////////////////////////////////////////////////////////////////////////

// Text default style
// ============================================================
const FONT_STYLES = {
  DEFAULT: css`
    font-weight: normal;
    text-transform: none;
  `,
  FAMILIES: {
    FIRST: {
      REGULAR: css`
        font-family: ${VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.REGULAR};
      `,

      MEDIUM: css`
        font-family: ${VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.MEDIUM};
      `,

      BOLD: css`
        font-family: ${VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.BOLD};
      `,
    },
  },
  SIZES: {
    FIRST: {
      FS_10: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_10 + `px`};
        line-height: ${Math.round(
        VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_10 *
        VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR
      ) + `px`};
      `,

      FS_11: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_11 + `px`};
        line-height: ${Math.round(
        VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_11 *
        VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR
      ) + `px`};
      `,

      FS_12: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_12 + `px`};
        line-height: ${Math.round(
        VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_12 *
        VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR
      ) + `px`};
      `,

      FS_14: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_14 + `px`};
        line-height: ${Math.round(
        VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_14 *
        VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR
      ) + `px`};
      `,

      FS_15: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_15 + `px`};
        line-height: ${Math.round(
        VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_15 *
        VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR
      ) + `px`};
      `,

      FS_16: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_16 + `px`};
        line-height: ${Math.round(
        VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_16 *
        VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR
      ) + `px`};
      `,

      FS_18: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_18 + `px`};
        line-height: ${Math.round(
        VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_18 *
        VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR
      ) + `px`};
      `,

      FS_20: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_20 + `px`};
        line-height: ${Math.round(
        VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_20 *
        VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR
      ) + `px`};
      `,

      FS_22: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_22 + `px`};
        line-height: ${Math.round(
        VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_22 *
        VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR
      ) + `px`};
      `,

      FS_24: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_24 + `px`};
        line-height: ${Math.round(
        VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_24 *
        VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR
      ) + `px`};
      `,

      FS_30: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_30 + `px`};
        line-height: ${Math.round(
        VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_30 *
        VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR
      ) + `px`};
      `,

      FS_36: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_36 + `px`};
        line-height: ${Math.round(
        VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_36 *
        VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR
      ) + `px`};
      `,

      FS_40: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_40 + `px`};
        line-height: ${Math.round(
        VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_40 *
        VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR
      ) + `px`};
      `,

      FS_42: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_42 + `px`};
        line-height: ${Math.round(
        VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_42 *
        VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR
      ) + `px`};
      `,

      FS_44: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_44 + `px`};
        line-height: ${Math.round(
        VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_44 *
        VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR
      ) + `px`};
      `,

      FS_48: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_48 + `px`};
        line-height: ${Math.round(
        VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_48 *
        VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR
      ) + `px`};
      `,

      FS_54: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_54 + `px`};
        line-height: ${Math.round(
        VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_54 *
        VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR
      ) + `px`};
      `,
    },
  },
};

export default {
  // First (Roboto)
  // ============================================================
  FIRST: {
    // Regular
    // ------------------------------
    REGULAR: {
      // Font size > 10px
      FS_10: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
       ${FONT_STYLES.SIZES.FIRST.FS_10}
      `,

      // Font size > 11px
      FS_11: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
       ${FONT_STYLES.SIZES.FIRST.FS_11}
      `,

      // Font size > 12px
      FS_12: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
       ${FONT_STYLES.SIZES.FIRST.FS_12}
      `,

      // Font size > 14px
      FS_14: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_14}
      `,

      // Font size > 15px
      FS_15: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_15}
      `,

      // Font size > 16px
      FS_16: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_16}
      `,

      // Font size > 18px
      FS_18: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_18}
      `,

      // Font size > 20px
      FS_20: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_20}
      `,

      // Font size > 22px
      FS_22: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_22}
      `,

      // Font size > 24px
      FS_24: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_24}
      `,

      // Font size > 30px
      FS_30: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_30}
      `,

      // Font size > 40px
      FS_40: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_40}
      `,

      // Font size > 42px
      FS_42: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_42}
      `,

      // Font size > 48px
      FS_48: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_48}
      `,

      // Font size > 54px
      FS_54: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_54}
      `,
    },

    // Medium
    // ------------------------------
    MEDIUM: {
      // Font size > 10px
      FS_10: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
       ${FONT_STYLES.SIZES.FIRST.FS_10}
      `,

      // Font size > 11px
      FS_11: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
       ${FONT_STYLES.SIZES.FIRST.FS_11}
      `,

      // Font size > 12px
      FS_12: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_12}
      `,

      // Font size > 14px
      FS_14: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_14}
      `,

      // Font size > 15px
      FS_15: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_15}
      `,

      // Font size > 16px
      FS_16: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_16}
      `,

      // Font size > 18px
      FS_18: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_18}
      `,

      // Font size > 20px
      FS_20: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_20}
      `,

      // Font size > 22px
      FS_22: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_22}
      `,

      // Font size > 24px
      FS_24: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_24}
      `,

      // Font size > 30px
      FS_30: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_30}
      `,

      // Font size > 40px
      FS_40: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_40}
      `,

      // Font size > 42px
      FS_42: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_42}
      `,

      // Font size > 48px
      FS_48: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_48}
      `,

      // Font size > 54px
      FS_54: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_54}
      `,
    },

    // Bold
    // ------------------------------
    BOLD: {
      // Font size > 10px
      FS_10: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
       ${FONT_STYLES.SIZES.FIRST.FS_10}
      `,

      // Font size > 11px
      FS_11: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
       ${FONT_STYLES.SIZES.FIRST.FS_11}
      `,

      // Font size > 12px
      FS_12: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_12}
      `,

      // Font size > 14px
      FS_14: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_14}
      `,

      // Font size > 15px
      FS_15: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_15}
      `,

      // Font size > 16px
      FS_16: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_16}
      `,

      // Font size > 18px
      FS_18: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_18}
      `,

      // Font size > 20px
      FS_20: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_20}
      `,

      // Font size > 22px
      FS_22: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_22}
      `,

      // Font size > 22px
      FS_24: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_24}
      `,

      // Font size > 30px
      FS_30: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_30}
      `,

      // Font size > 36px
      FS_36: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_36}
      `,

      // Font size > 40px
      FS_40: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_40}
      `,

      // Font size > 42px
      FS_42: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_42}
      `,

      // Font size > 44px
      FS_44: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_44}
      `,

      // Font size > 48px
      FS_48: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_48}
      `,

      // Font size > 54px
      FS_54: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_54}
      `,
    },
  },
};
